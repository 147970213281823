<template>
  <div class="section-3 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <div class="evo-text-58 evo-title">
        {{ $t('home.s3_title') }}
      </div>
      <v-row justify="space-between" align="center">
        <v-col class="evo-provide-col" cols="12" v-for="item in mergedItems" :key="item.key">
          <div class="evo-provide-card d-flex flex-column">
            <div>
              <img loading="lazy" :src="item.src" class="evo-section3-img" />
            </div>
            <div class="evo-provide-text evo-text-36">
              {{ $t(item.text) }}
            </div>
            <div class="evo-provide-subtext evo-text-24">
              {{ $t(item.subText) }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { walletNetworkSection3Mixin } from '@/mixins/wallet-network.js';
export default {
  name: 'EvoMWalletNetworkSectionThree',
  mixins: [walletNetworkSection3Mixin],
  data: () => ({
    mergedItems: []
  }),
  created() {
    this.mergedItems = [...this.items, ...this.items2];
  }
};
</script>

<style lang="scss" scoped>
.section-3 {
  background: #fafafa;
  position: relative;

  .evo-title {
    position: relative;
    z-index: 1;
    text-align: center;
    color: #1f1f1f;
    margin-bottom: 64px;
  }

  .evo-provide-col {
    position: relative;
    z-index: 1;

    .evo-section3-img {
      width: 86px;
      height: 86px;
      margin-bottom: 8px;
    }
  }

  .evo-provide-card {
    max-width: 680px;
    margin: auto;
    padding: 48px;
    border-radius: 17px;
    box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.03);
    background: #ffffff;

    .evo-provide-text {
      font-weight: bold;
      margin-bottom: 8px;
    }

    .evo-provide-subtext {
      font-family: 'OpenSans-Regular' !important;
    }
  }
}
</style>
