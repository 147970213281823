<template>
  <div
    class="section-8 evo-ph-206 evo-pv-60"
    :class="{
      'evo-pv-100': $vuetify.breakpoint.xsOnly,
      'evo-ph-80': $vuetify.breakpoint.xsOnly
    }"
  >
    <v-row class="px-0 mb-4" align="center" justify="start">
      <div class="evo-section8-title evo-text-23" :class="{ 'evo-px-text-23': $vuetify.breakpoint.xsOnly }" data-aos="fade-up">
        {{ $t('home.trusted_industry_compliance_and_system_security') }}
      </div>
    </v-row>
    <v-row class="px-0 evo-mb-42" align="center" justify="center">
      <div
        class="evo-section8-subtitle evo-text-12 evo-opensans-regular"
        :class="{ 'evo-px-text-10': $vuetify.breakpoint.xsOnly }"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        {{ $t('home.desc_section_8_1') }}
      </div>
    </v-row>
    <v-row class="px-0 evo-mb-28" align="start" justify="space-between">
      <v-col
        class="px-0"
        v-for="item in items1"
        :key="item.id"
        cols="12"
        md="3"
        sm="12"
        :class="{ 'mb-1': $vuetify.breakpoint.xsOnly }"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <div class="d-flex flex-column align-center justify-center evo-standard-img-wrapper">
          <img loading="lazy" class="evo-standard-img" v-if="item.src" :src="item.src" />
        </div>
        <div class="evo-stadard-title evo-opensans-regular evo-text-10">
          {{ $t(item.title) }}
        </div>
      </v-col>
    </v-row>
    <v-row class="px-0 evo-mb-28" align="start" justify="space-between">
      <v-col
        class="px-0"
        v-for="item in items2"
        :key="item.id"
        cols="12"
        md="3"
        sm="12"
        :class="{ 'mb-1': $vuetify.breakpoint.xsOnly }"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <div class="d-flex flex-column align-center justify-center evo-standard-img-wrapper">
          <img loading="lazy" class="evo-standard-img" v-if="item.src" :src="item.src" />
        </div>
        <div class="evo-stadard-title evo-opensans-regular evo-text-10">
          {{ $t(item.title) }}
        </div>
      </v-col>
    </v-row>

    <!-- <EvoRect class="evo-section8-rect1 mb-2" /> -->
    <EvoRect color="gradientBlue" class="evo-section8-rect2" />
    <EvoEllipse v-if="!$vuetify.breakpoint.xsOnly" class="evo-section8-ellipse" width="132px" height="137px" />
  </div>
</template>

<script>
import { walletNetworkSection8Mixin } from '@/mixins/wallet-network.js';
export default {
  name: 'EvoHomeSectionEight',
  mixins: [walletNetworkSection8Mixin]
};
</script>

<style lang="scss" scoped>
.section-8 {
  position: relative;
  overflow: hidden;

  .evo-section8-header {
    margin-bottom: 17px;
    .v-divider {
      margin: auto;
      width: 51px;
      max-width: 51px;
      border-color: #a0a3a8;
    }

    .evo-section8-header-text-col {
      padding-left: 0 !important;
      padding-right: 0 !important;
      text-align: end !important;
    }

    .evo-section8-header-text {
      font-weight: bold;
      color: #a0a3a8;
    }
  }

  .evo-section8-title {
    font-weight: bold;
    color: $title_color;
    white-space: pre-wrap;
    text-align: left !important;
  }

  .evo-section8-subtitle {
    color: #a0a3a8;
  }

  .evo-standard-img-wrapper {
    background: white;
    width: 100%;
    height: 80px;
    border-radius: 11px;
    margin-bottom: 10px;
    .evo-standard-img {
      width: 70px;
      height: auto;
    }
  }

  .evo-stadard-title {
    color: $title_color;
    text-align: center;
  }

  .evo-section8-wrapper {
    height: 81px;
  }

  .evo-section8-rect1 {
    position: absolute;
    width: 107px;
    height: 47px;
    left: 37px;
    bottom: 50px;
  }

  .evo-section8-rect2 {
    position: absolute;
    width: 100vw;
    height: 39px;
    left: 0;
    bottom: 20px;
  }

  .evo-section8-ellipse {
    position: absolute;
    right: 10px;
    top: 50%;
  }
}
</style>
