<template>
  <div class="section-2 evo-ph-206 evo-pv-100">
    <div class="evo-text-34 evo-title" data-aos="fade-up">
      {{ $t('home.s2_title') }}
    </div>
    <div class="evo-text-14 evo-opensans-regular evo-sub-title" data-aos="fade-up" data-aos-delay="100">
      {{ $t('home.s2_sub_title') }}
    </div>

    <v-row class="evo-row" justify="space-between">
      <v-col cols="12" md="6" v-for="(item, index) in introduces" :key="index">
        <div class="evo-introduces" data-aos="fade-up" :data-aos-delay="item.delay">
          <div class="evo-circle-dot"></div>
          <div class="evo-opensans-regular evo-item-desc evo-text-12">
            {{ $t(item.desc) }}
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="evo-row">
      <v-col cols="12" md="3" v-for="(item, index) in items" :key="index">
        <div class="evo-items">
          <img loading="lazy" :src="item.src" class="evo-item-img" data-aos="fade-up" />
          <div class="evo-item-name evo-text-12 evo-opensans-regular">{{ $t(item.name) }}</div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { walletNetworkSection2Mixin } from '@/mixins/wallet-network.js';
export default {
  name: 'EvoHomeSectionTwo',
  mixins: [walletNetworkSection2Mixin]
};
</script>

<style lang="scss" scoped>
.section-2 {
  position: relative;

  .evo-title {
    color: $title_color;
    white-space: pre-line;
    margin-bottom: 8px;
  }

  .evo-sub-title {
    color: #a0a3a8;
    margin-bottom: 64px;
  }

  .evo-introduces {
    display: flex;
    align-items: baseline;
    margin-bottom: 64px;

    .evo-item-desc {
      color: #1f1f1f;
    }
  }

  .evo-items {
    display: flex;
    flex-direction: column;
    align-items: center;

    .evo-item-img {
      width: 132px;
      height: 132px;
      margin-bottom: 16px;
    }

    .evo-item-name {
      color: #a0a3a8;
    }
  }

  .evo-circle-dot {
    &::before {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      background: #2161ff;
      border-radius: 50%;
      margin-right: 20px;
    }
  }
}
</style>
