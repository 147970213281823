<template>
  <div class="section-8 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <div class="evo-section8-title evo-text-58">
        {{ $t('home.trusted_industry_compliance_and_system_security') }}
      </div>
      <div class="evo-section8-subtitle evo-text-30 evo-opensans-regular">
        {{ $t('home.desc_section_8_1') }}
      </div>

      <div class="evo-item-wrapper" v-for="item in items1" :key="item.id">
        <div class="d-flex flex-column align-center justify-center evo-standard-img-wrapper">
          <img loading="lazy" class="evo-standard-img" v-if="item.src" :src="item.src" />
        </div>
        <div class="evo-stadard-title evo-opensans-regular evo-text-40">
          {{ $t(item.title) }}
        </div>
      </div>
      <div class="evo-item-wrapper" v-for="item in items2" :key="item.id">
        <div class="d-flex flex-column align-center justify-center evo-standard-img-wrapper">
          <img loading="lazy" class="evo-standard-img" v-if="item.src" :src="item.src" />
        </div>
        <div class="evo-stadard-title evo-opensans-regular evo-text-40">
          {{ $t(item.title) }}
        </div>
      </div>

      <EvoRect color="gradientBlue" class="evo-section8-rect2" />
      <EvoEllipse v-if="!$vuetify.breakpoint.xsOnly" class="evo-section8-ellipse" width="132px" height="137px" />
    </div>
  </div>
</template>

<script>
import { walletNetworkSection8Mixin } from '@/mixins/wallet-network.js';
export default {
  name: 'EvoMWalletNetworkSectionEight',
  mixins: [walletNetworkSection8Mixin]
};
</script>

<style lang="scss" scoped>
.section-8 {
  position: relative;
  overflow: hidden;

  .evo-section8-title {
    color: $title_color;
    white-space: pre-line;
    text-align: center !important;
    margin-bottom: 8px;
  }

  .evo-section8-subtitle {
    color: #a0a3a8;
    margin-bottom: 48px;
  }

  .evo-item-wrapper {
    margin-bottom: 124px;
  }

  .evo-standard-img-wrapper {
    margin-bottom: 48px;
    .evo-standard-img {
      width: 358px;
      height: auto;
    }
  }

  .evo-stadard-title {
    color: $title_color;
    text-align: center;
  }

  .evo-section8-rect2 {
    position: absolute;
    width: 100vw;
    height: 39px;
    left: 0;
    bottom: 20px;
  }

  .evo-section8-ellipse {
    position: absolute;
    right: 10px;
    top: 50%;
  }
}
</style>
