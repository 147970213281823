<template>
  <div class="evo-home-wrapper">
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMWalletNetworkSectionOne />
      <EvoMWalletNetworkSectionTwo />
      <EvoMWalletNetworkSectionThree />
      <EvoMWalletNetworkSectionFour />
      <EvoMWalletNetworkSectionSeven />
      <EvoMWalletNetworkSectionEight />
      <EvoMWalletNetworkSectionSix />
      <EvoMMainSectionTwo />
    </template>
    <template v-else>
      <EvoHomeSectionOne />
      <EvoHomeSectionTwo />
      <EvoHomeSectionThree />
      <EvoHomeSectionFour />
      <EvoHomeSectionSeven />
      <EvoHomeSectionEight />
      <EvoHomeSectionSix />
      <EvoMainSectionTwo />
    </template>
  </div>
</template>

<script>
import EvoHomeSectionOne from '@/components/wallet_network_sections/Section_1.vue';
import EvoHomeSectionTwo from '@/components/wallet_network_sections/Section_2.vue';
import EvoHomeSectionThree from '@/components/wallet_network_sections/Section_3.vue';
import EvoHomeSectionFour from '@/components/wallet_network_sections/Section_4.vue';
import EvoHomeSectionSix from '@/components/wallet_network_sections/Section_6.vue';
import EvoHomeSectionSeven from '@/components/wallet_network_sections/Section_7.vue';
import EvoHomeSectionEight from '@/components/wallet_network_sections/Section_8.vue';
import EvoMainSectionTwo from '@/components/main_sections/Section_2.vue';

// mobile imports
import EvoMWalletNetworkSectionOne from '@/mobile/components/wallet_network_sections/MSection_1.vue';
import EvoMWalletNetworkSectionTwo from '@/mobile/components/wallet_network_sections/MSection_2.vue';
import EvoMWalletNetworkSectionThree from '@/mobile/components/wallet_network_sections/MSection_3.vue';
import EvoMWalletNetworkSectionFour from '@/mobile/components/wallet_network_sections/MSection_4.vue';
import EvoMWalletNetworkSectionSeven from '@/mobile/components/wallet_network_sections/MSection_7.vue';
import EvoMWalletNetworkSectionEight from '@/mobile/components/wallet_network_sections/MSection_8.vue';
import EvoMWalletNetworkSectionSix from '@/mobile/components/wallet_network_sections/MSection_6.vue';
import EvoMMainSectionTwo from '@/mobile/components/main_sections/MSection_2.vue';
export default {
  name: 'EvoHome',

  components: {
    EvoHomeSectionOne,
    EvoHomeSectionTwo,
    EvoHomeSectionThree,
    EvoHomeSectionFour,
    // EvoHomeSectionFive,
    EvoHomeSectionSix,
    EvoHomeSectionSeven,
    EvoHomeSectionEight,
    EvoMainSectionTwo,

    EvoMWalletNetworkSectionOne,
    EvoMWalletNetworkSectionTwo,
    EvoMWalletNetworkSectionThree,
    EvoMWalletNetworkSectionFour,
    EvoMWalletNetworkSectionSeven,
    EvoMWalletNetworkSectionEight,
    EvoMWalletNetworkSectionSix,
    EvoMMainSectionTwo
  }
};
</script>

<style lang="scss" scoped>
.evo-home-wrapper {
  background: $header_bg_color;
}
</style>
