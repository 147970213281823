<template>
  <div>
    <EvoCodeHeader
      :title="title"
      :desc="desc"
      :color="colorHeader"
      :background="background"
    />
    <prism-editor
      readonly
      class="my-editor evo-px-text-14 height-editor mb-6"
      :style="{ color: color, background: background }"
      v-model="code"
      :highlight="highlighter"
      line-numbers
    ></prism-editor>
  </div>
</template>

<script>
import EvoCodeHeader from "@/components/CodeHeader.vue";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-json";
import "prismjs/components/prism-json5";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles
export default {
  name: "EvoCodeViewer",
  props: {
    language: {
      type: String,
      default: "bash",
    },
    title: {
      type: String,
      default: "home.request",
    },
    desc: {
      type: String,
      default: "home.examples",
    },
    color: {
      type: String,
      default: "#27b59e",
    },
    background: {
      type: String,
      default: "#273045",
    },
    colorHeader: {
      type: String,
      default: "#ffffff",
    },
    code: {
      type: String,
      default: "",
    },
  },
  components: {
    EvoCodeHeader,
    PrismEditor,
  },
  methods: {
    highlighterBash(code) {
      return highlight(code, languages.bash); // languages.<insert language> to return html with markup
    },
    highlighterJson(code) {
      return highlight(code, languages.json);
    },
    highlighterJson5(code) {
      return highlight(code, languages.json5);
    },
    highlighter(code) {
      switch (this.language) {
        case "bash":
          return this.highlighterBash(code);
        case "json":
          return this.highlighterJson(code);
        case "json5":
          return this.highlighterJson5(code);
        default:
          break;
      }

      return this.highlighterBash(code);
    },
  },
};
</script>

<style lang="scss" scoped>
/* required class */
.my-editor {
  font-family: "OpenSans-Regular", sans-serif;
  line-height: 1.8;
  padding: 5px;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}

/* optional class for removing the outline */
::v-deep(.prism-editor__textarea) {
  &:focus,
  &:focus-visible {
    outline: none !important;
  }
}

::v-deep(.prism-editor__line-numbers) {
  min-height: auto !important;
}

// not required:
.height-editor {
  height: auto;
}
</style>
