<template>
  <div class="section-4 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <div class="evo-text-58 text-center evo-text-solution">
        {{ $t('home.our_solutions') }}
      </div>
      <v-row class="evo-content-wrapper" justify="center" align="center">
        <v-col class="evo-solution-col" cols="12" v-for="item in items" :key="item.key">
          <div class="d-flex pl-9 mb-10">
            <img loading="lazy" :src="item.src" />
          </div>
          <div class="evo-text-30 evo-opensans-bold mb-2" v-html="$t(item.subtitle)"></div>
          <div class="mt-3 mb-10 evo-text-24 evo-opensans-regular">
            {{ $t(item.text) }}
          </div>
          <v-btn class="evo-read-more text-none" :ripple="false" plain @click="goTo(item.path)">
            {{ $t('home.read_more') }}
            <v-icon right> $vuetify.icons.mdiArrowRight </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { walletNetworkSection4Mixin } from '@/mixins/wallet-network.js';
export default {
  name: 'EvoMWalletNetworkSectionFour',
  mixins: [walletNetworkSection4Mixin]
};
</script>

<style lang="scss" scoped>
.section-4 {
  position: relative;
  background: #f4f6f9;

  .evo-text-solution {
    color: #1f1f1f;
    margin-bottom: 64px;
  }

  .evo-content-wrapper {
    position: relative;
    z-index: 1;

    .evo-solution-col {
      position: relative;
      background: #ffffff;
      border-radius: 11px;
      position: relative;
      max-width: 680px;
      margin-bottom: 48px;
      padding: 48px;

      img {
        width: 212px;
        height: auto;
      }

      .evo-read-more {
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-weight: bold;
        color: #ff924a;
        letter-spacing: initial !important;
      }
    }
  }
}
</style>
