<template>
  <div
    class="section-4 evo-ph-206 evo-pv-60"
    :class="{
      'evo-pv-100': $vuetify.breakpoint.xsOnly,
      'evo-ph-80': $vuetify.breakpoint.xsOnly,
    }"
  >
    <v-row
      align="center"
      justify="center"
      class="px-0 section-3-row-1"
      :class="{ 'evo-px-mb-75': $vuetify.breakpoint.xsOnly }"
    >
      <div
        class="evo-text-23 evo-text-solution"
        :class="{
          'evo-px-text-23': $vuetify.breakpoint.xsOnly,
        }"
        data-aos="fade-up"
      >
        {{ $t("home.our_solutions") }}
      </div>
    </v-row>
    <div class="evo-space" v-if="!$vuetify.breakpoint.xsOnly"></div>
    <v-row class="evo-content-wrapper" justify="center">
      <v-col
        class="evo-solution-col"
        :class="{ 'evo-xs-solution-item': $vuetify.breakpoint.xsOnly }"
        v-for="item in items"
        :key="item.key"
        data-aos="fade-up" data-aos-delay="100"
      >
        <div class="d-flex pl-9 mb-10">
          <img loading="lazy"  :src="item.src" />
        </div>
        <div
          class="evo-text-16 evo-opensans-bold mb-2"
          v-html="$t(item.subtitle)"
        ></div>
        <div class="mt-3 mb-10 evo-text-12 evo-opensans-regular">
          {{ $t(item.text) }}
        </div>
        <v-btn
          class="evo-read-more text-none"
          :ripple="false"
          plain
          @click="goTo(item.path)"
        >
          {{ $t("home.read_more") }}
          <v-icon right> $vuetify.icons.mdiArrowRight </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <EvoEllipse
      v-if="!$vuetify.breakpoint.xsOnly"
      class="evo-section4-ellipse1"
      background="yellow"
      width="188px"
      height="188px"
    />

    <EvoEllipse
      v-if="!$vuetify.breakpoint.xsOnly"
      class="evo-section4-ellipse2"
      background="blue"
      width="168px"
      height="168px"
    />
  </div>
</template>

<script>
import { walletNetworkSection4Mixin } from '@/mixins/wallet-network.js';
export default {
  name: "EvoHomeSectionFour",
  mixins: [walletNetworkSection4Mixin]
};
</script>

<style lang="scss" scoped>
.section-4 {
  position: relative;
  background: #f4f6f9;
  padding-top: 100px !important;
  padding-bottom: 100px !important;

  .evo-text-solution {
    font-weight: bold;
    color: #1f1f1f;
  }

  .evo-content-wrapper {
    position: relative;
    z-index: 1;
    margin-left: -206px;
    margin-right: -206px;
    .evo-xs-solution-item {
      width: 100% !important;
      max-width: 100% !important;
      margin-bottom: 100px;

      .evo-px-point-right-item {
        right: 30px !important;
        top: 30px !important;
        .evo-px-point-right-img {
          width: 27px !important;
          height: 27px !important;
        }
      }
    }

    .evo-solution-col {
      position: relative;
      background: #ffffff;
      border-radius: 11px;
      position: relative;
      max-width: 349px;
      padding: 25px;

      .evo-point-right-item {
        z-index: 1;
        position: absolute;
        right: 30px;
        top: 30px;
        cursor: pointer;

        .evo-point-right-img {
          width: 27px;
          height: 27px;
        }
      }

      .evo-solution-card {
        padding: 55px 15px;
        border-radius: 17px;
        box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.03);
        background: #ffffff;

        .evo-subtitle {
          font-weight: 600;
        }
      }

      .evo-solution-item-height {
        height: 220px;
      }

      .evo-read-more {
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-weight: bold;
        color: #ff924a;
        letter-spacing: initial !important;
      }
    }

    .evo-solution-col + .evo-solution-col {
      margin-left: 77px;
    }
  }

  .evo-space {
    height: 60px;
  }

  .evo-section4-ellipse1 {
    position: absolute;
    left: 430px;
    top: 45%;
  }

  .evo-section4-ellipse2 {
    position: absolute;
    right: 105px;
    top: 45%;
  }
}
</style>
