<template>
  <div
    class="section-1 section-1-bg evo-ph-176 evo-pv-60"
    :class="{
      'evo-pv-100': $vuetify.breakpoint.xsOnly,
      'evo-ph-80': $vuetify.breakpoint.xsOnly,
    }"
  >
    <v-row align="start" justify="center" class="evo-px-pa-12 px-0">
      <!-- left -->
      <v-col class="px-0" cols="12" md="5" sm="12" padless>
        <div
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
          class="evo-section1-title evo-text-34 evo-mt-34"
          :class="{
            'mb-xs-0': $vuetify.breakpoint.xsOnly,
            'evo-px-text-34': $vuetify.breakpoint.xsOnly,
          }"
        >
          {{ $t("home.expand") }}
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
          class="evo-section1-title evo-text-34"
          :class="{
            'mb-xs-0': $vuetify.breakpoint.xsOnly,
            'evo-px-text-34': $vuetify.breakpoint.xsOnly,
          }"
        >
          {{ $t("home.global_payment") }}
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
          class="evo-section1-title evo-text-34"
          :class="{
            'mb-xs-0': $vuetify.breakpoint.xsOnly,
            'evo-px-text-34': $vuetify.breakpoint.xsOnly,
          }"
        >
          {{ $t("home.enhance") }}
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          class="evo-section1-subtitle evo-text-16 evo-mb-28 evo-mt-28 evo-opensans-regular "
          :class="{
            'evo-px-text-12': $vuetify.breakpoint.xsOnly,
            'evo-px-mb-28': $vuetify.breakpoint.xsOnly,
          }"
        >
          {{
            $t(
              "home.deliver_exceptional_business_values_to_mobile_wallets_networks_merchant_acquirers_and_financial_service_providers"
            )
          }}
        </div>
        <router-link to="contact-us" class="router-link">
          <v-btn
            data-aos="fade-up"
            data-aos-delay="150"
            data-aos-duration="1000"
            rounded
            x-large
            color="default"
            class="text-none evo-text-9 evo-contact-us "
            :class="{
              'evo-px-mb-28': $vuetify.breakpoint.xsOnly,
            }"
          >
            {{ $t("contact_us") }}
          </v-btn>
        </router-link>
      </v-col>
      <!-- right -->
      <v-col class="px-0" cols="12" md="7" xs="12" padless>
        <img
          loading="lazy"
          src="@/assets/home/section1.png"
          class="evo-section1-img"
          :class="{ 'evo-img-auto': $vuetify.breakpoint.xsOnly }"
          data-aos="fade-up"
          data-aos-delay="100"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "EvoHomeSectionOne",
};
</script>

<style lang="scss" scoped>
.section-1 {
  background-color: $nav_color;
  position: relative;
  padding-right: 76px !important;
  padding-bottom: 0 !important;
  padding-top: 48px !important;

  .evo-px-diamond {
    margin-left: 10px;
    margin-bottom: 24px;
  }

  .evo-section1-title {
    font-weight: bold;
    margin-bottom: 19px;
    line-height: 0.8;
  }

  .evo-contact-us {
    @include whiteblue_btn;
    width: 140px;
  }

  .evo-section1-img {
    width: 100%;
    height: auto;
  }
}
</style>
