<template>
  <div class="section-6 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <div class="evo-section6-title evo-text-58">
        {{ $t('home.a_powerful_wallet_demo_app') }}
      </div>
      <div class="evo-s6-subtitle-wrapper">
        <div class="evo-section6-subtitle evo-text-30 mb-2 evo-opensans-regular">
          {{ $t('home.evo_wallet_has_been_implemented_with_all_evonet_products') }}
        </div>
        <div class="evo-section6-subtitle evo-text-30 evo-mb-28 evo-opensans-regular">
          {{ $t('home.contact_us_and_get_the_demo_to_start_the_user_journey') }}
        </div>
      </div>
      <v-btn x-large rounded dark class="text-none evo-text-29 evo-contact-btn" @click="goTo('/contact-us')">
        {{ $t('home.learn_more') }}
      </v-btn>

      <div class="evo-section6-img-container d-flex flex-column justify-center align-center">
        <img loading="lazy" src="@/assets/home/phone-wallet-pay.png" class="evo-section6-img" />
      </div>
    </div>
  </div>
</template>

<script>
import { walletNetworkSection6Mixin } from '@/mixins/wallet-network.js';
export default {
  name: 'EvoMWalletNetworkSectionSix',
  mixins: [walletNetworkSection6Mixin]
};
</script>

<style lang="scss" scoped>
.section-6 {
  background: $header_bg_color;
  position: relative;

  .evo-section6-img-container {
    padding: 16px;
    background: #fafafa;
    box-shadow: 2px 4px 54px 0px rgba(166, 180, 195, 0.5), 0px 1px 7px 0px rgba(222, 225, 234, 0.5);
    border-radius: 48px;
    max-width: 600px;
    margin: auto;
  }

  .evo-section6-img {
    width: 100%;
  }

  .evo-section6-title {
    font-weight: bold;
    color: $title_color;
    margin-bottom: 48px;
  }

  .evo-s6-subtitle-wrapper {
    margin-bottom: 64px;
  }

  .evo-section6-subtitle {
    color: #a0a3a8;
  }

  .evo-contact-btn {
    min-width: 255px;
    background: #1f252d;
    letter-spacing: normal;
    margin-bottom: 96px;
  }
}
</style>
