var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-1 section-1-bg evo-ph-176 evo-pv-60",class:{
    'evo-pv-100': _vm.$vuetify.breakpoint.xsOnly,
    'evo-ph-80': _vm.$vuetify.breakpoint.xsOnly,
  }},[_c('v-row',{staticClass:"evo-px-pa-12 px-0",attrs:{"align":"start","justify":"center"}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","md":"5","sm":"12","padless":""}},[_c('div',{staticClass:"evo-section1-title evo-text-34 evo-mt-34",class:{
          'mb-xs-0': _vm.$vuetify.breakpoint.xsOnly,
          'evo-px-text-34': _vm.$vuetify.breakpoint.xsOnly,
        },attrs:{"data-aos":"fade-up","data-aos-delay":"50","data-aos-duration":"1000"}},[_vm._v(" "+_vm._s(_vm.$t("home.expand"))+" ")]),_c('div',{staticClass:"evo-section1-title evo-text-34",class:{
          'mb-xs-0': _vm.$vuetify.breakpoint.xsOnly,
          'evo-px-text-34': _vm.$vuetify.breakpoint.xsOnly,
        },attrs:{"data-aos":"fade-up","data-aos-delay":"50","data-aos-duration":"1000"}},[_vm._v(" "+_vm._s(_vm.$t("home.global_payment"))+" ")]),_c('div',{staticClass:"evo-section1-title evo-text-34",class:{
          'mb-xs-0': _vm.$vuetify.breakpoint.xsOnly,
          'evo-px-text-34': _vm.$vuetify.breakpoint.xsOnly,
        },attrs:{"data-aos":"fade-up","data-aos-delay":"50","data-aos-duration":"1000"}},[_vm._v(" "+_vm._s(_vm.$t("home.enhance"))+" ")]),_c('div',{staticClass:"evo-section1-subtitle evo-text-16 evo-mb-28 evo-mt-28 evo-opensans-regular",class:{
          'evo-px-text-12': _vm.$vuetify.breakpoint.xsOnly,
          'evo-px-mb-28': _vm.$vuetify.breakpoint.xsOnly,
        },attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"1000"}},[_vm._v(" "+_vm._s(_vm.$t( "home.deliver_exceptional_business_values_to_mobile_wallets_networks_merchant_acquirers_and_financial_service_providers" ))+" ")]),_c('router-link',{staticClass:"router-link",attrs:{"to":"contact-us"}},[_c('v-btn',{staticClass:"text-none evo-text-9 evo-contact-us",class:{
            'evo-px-mb-28': _vm.$vuetify.breakpoint.xsOnly,
          },attrs:{"data-aos":"fade-up","data-aos-delay":"150","data-aos-duration":"1000","rounded":"","x-large":"","color":"default"}},[_vm._v(" "+_vm._s(_vm.$t("contact_us"))+" ")])],1)],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","md":"7","xs":"12","padless":""}},[_c('img',{staticClass:"evo-section1-img",class:{ 'evo-img-auto': _vm.$vuetify.breakpoint.xsOnly },attrs:{"loading":"lazy","src":require("@/assets/home/section1.png"),"data-aos":"fade-up","data-aos-delay":"100"}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }