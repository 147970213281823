var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-7 evo-ph-206 evo-pv-60",class:{
    'evo-pv-100': _vm.$vuetify.breakpoint.xsOnly,
    'evo-ph-80': _vm.$vuetify.breakpoint.xsOnly,
  }},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"pa-0 pr-8",attrs:{"cols":"12","sm":"12","md":"5"}},[_c('div',{staticClass:"evo-section7-title evo-text-21",class:{
          'evo-px-text-21': _vm.$vuetify.breakpoint.xsOnly,
        },attrs:{"data-aos":"fade-up"}},[_vm._v(" "+_vm._s(_vm.$t("home.easy_to_read"))+" ")]),_c('div',{staticClass:"evo-section7-title evo-text-21 mb-6",class:{
          'evo-px-text-21': _vm.$vuetify.breakpoint.xsOnly,
        },attrs:{"data-aos":"fade-up","data-aos-delay":"100"}},[_vm._v(" "+_vm._s(_vm.$t("home.easy_to_use"))+" ")]),_c('div',{staticClass:"evo-section7-subtitle evo-text-12 evo-mb-28 evo-opensans-regular",class:{
          'mb-10': _vm.$vuetify.breakpoint.xsOnly,
          'evo-px-text-9': _vm.$vuetify.breakpoint.xsOnly,
        },attrs:{"data-aos":"fade-up","data-aos-delay":"150"}},[_vm._v(" "+_vm._s(_vm.$t("home.desc_section_7_1"))+" ")]),_c('v-btn',{staticClass:"text-none evo-text-14 evo-doc-btn",class:{ 'mb-10': _vm.$vuetify.breakpoint.xsOnly },attrs:{"x-large":"","rounded":"","dark":"","data-aos":"fade-up","data-aos-delay":"200"},on:{"click":_vm.onOpenAPIs}},[_vm._v(_vm._s(_vm.$t("get_open_apis")))])],1),_c('v-col',{staticClass:"pa-0",class:{ 'evo-px-mb-28': _vm.$vuetify.breakpoint.xsOnly },attrs:{"cols":"12","sm":"12","md":"7"}},[_c('EvoCodeViewer',{attrs:{"code":_vm.req,"colorHeader":"#1F1F1F","color":"#273045","background":"#e3e9f2","data-aos":"fade-up","data-aos-delay":"200"}}),_c('EvoCodeViewer',{attrs:{"code":_vm.res,"language":"json","title":'home.response',"data-aos":"fade-up","data-aos-delay":"250"}})],1)],1),_c('img',{staticClass:"evo-section7-parallelogram",attrs:{"loading":"lazy","src":require("@/assets/parallelogram.png")}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }