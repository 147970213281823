<template>
  <div
    class="evo-code-header evo-px-text-16 d-flex justify-space-between"
    :style="{ background: background, color: color }"
    :class="{ 'evo-px-pa-12': $vuetify.breakpoint.xsOnly }"
  >
    <div>{{ $t(title) }}</div>
    <div class="d-flex">
      <div>{{ $t(desc) }}</div>
      <!-- <v-icon :color="color" right>mdi-menu-down</v-icon> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "EvoCodeHeader",
  props: {
    title: {
      type: String,
      default: "home.request",
    },
    desc: {
      type: String,
      default: "home.examples",
    },
    color: {
      type: String,
      default: "#FFFFFF",
    },
    background: {
      type: String,
      default: "#273045",
    },
  },
};
</script>

<style lang="scss" scoped>
.evo-code-header {
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  padding: 10px;
  padding-bottom: 5px;
}
</style>
