<template>
  <div
    class="section-7 evo-ph-206 evo-pv-60"
    :class="{
      'evo-pv-100': $vuetify.breakpoint.xsOnly,
      'evo-ph-80': $vuetify.breakpoint.xsOnly,
    }"
  >
    <v-row justify="center" align="center">
      <v-col class="pa-0 pr-8" cols="12" sm="12" md="5">
        <div
          class="evo-section7-title evo-text-21"
          :class="{
            'evo-px-text-21': $vuetify.breakpoint.xsOnly,
          }"
          data-aos="fade-up"
        >
          {{ $t("home.easy_to_read") }}
        </div>
        <div
          class="evo-section7-title evo-text-21 mb-6"
          :class="{
            'evo-px-text-21': $vuetify.breakpoint.xsOnly,
          }"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          {{ $t("home.easy_to_use") }}
        </div>
        <div
          class="
            evo-section7-subtitle evo-text-12 evo-mb-28 evo-opensans-regular
          "
          :class="{
            'mb-10': $vuetify.breakpoint.xsOnly,
            'evo-px-text-9': $vuetify.breakpoint.xsOnly,
          }"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          {{ $t("home.desc_section_7_1") }}
        </div>
        <v-btn
          x-large
          rounded
          class="text-none evo-text-14 evo-doc-btn"
          dark
          :class="{ 'mb-10': $vuetify.breakpoint.xsOnly }"
          @click="onOpenAPIs"
          data-aos="fade-up"
          data-aos-delay="200"
          >{{ $t("get_open_apis") }}</v-btn
        >
      </v-col>
      <v-col
        class="pa-0"
        cols="12"
        sm="12"
        md="7"
        :class="{ 'evo-px-mb-28': $vuetify.breakpoint.xsOnly }"
      >
        <EvoCodeViewer
          :code="req"
          colorHeader="#1F1F1F"
          color="#273045"
          background="#e3e9f2"
          data-aos="fade-up"
          data-aos-delay="200"
        />
        <EvoCodeViewer
          :code="res"
          language="json"
          :title="'home.response'"
          data-aos="fade-up"
          data-aos-delay="250"
        />
      </v-col>
    </v-row>
    <img loading="lazy"  class="evo-section7-parallelogram" src="@/assets/parallelogram.png" />
  </div>
</template>

<script>
import { walletNetworkSection7Mixin } from '@/mixins/wallet-network.js';

export default {
  name: "EvoHomeSectionSeven",
  mixins: [walletNetworkSection7Mixin]
};
</script>

<style lang="scss" scoped>
.section-7 {
  background: $header_bg_color;
  position: relative;

  .evo-section7-header {
    & > .v-divider {
      width: 17px;
      max-width: 17px;
    }

    .evo-section7-rotate-text {
      font-weight: bold;
      color: $title_color;
    }
  }

  .evo-section7-title {
    font-weight: bold;
    color: $title_color;
  }

  .evo-section7-subtitle {
    color: #a0a3a8;
  }

  .evo-section7-parallelogram {
    position: absolute;
    width: 308px;
    height: 148px;
    left: 0;
    bottom: 0;
  }
  .evo-doc-btn {
    width: 183px;
    background: #1f252d;
    letter-spacing: normal;
  }
}
</style>
