
import EvoRect from "@/components/Rect.vue";
import EvoEllipse from "@/components/Ellipse.vue";
import EvoCodeViewer from "@/components/CodeViewer.vue";

export const walletNetworkSection2Mixin = {
    data: () => ({
        items: [
            {
                name: "home.s2_img_name_1",
                src: require("@/assets/home/section2_1.png"),
            },
            {
                name: "home.s2_img_name_2",
                src: require("@/assets/home/section2_2.png"),
            },
            {
                name: "home.s2_img_name_3",
                src: require("@/assets/home/section2_3.png"),
            },
            {
                name: "home.s2_img_name_4",
                src: require("@/assets/home/section2_4.png"),
            },
        ],
        introduces: [
            {
                desc: "home.desc_section_2_1",
                delay: 100,
            },
            {
                desc: "home.desc_section_2_2",
                delay: 150,
            },
        ],
    }),
}

export const walletNetworkSection3Mixin = {
    data: () => ({
        items: [
            {
                key: 1,
                src: require("@/assets/home/section3-1.png"),
                text: "home.s3_title_1",
                subText: "home.desc_section_3_1",
            },
            {
                key: 2,
                src: require("@/assets/home/section3-2.png"),
                text: "home.s3_title_2",
                subText: "home.desc_section_3_2",
            },
            {
                key: 3,
                src: require("@/assets/home/section3-3.png"),
                text: "home.s3_title_3",
                subText: "home.desc_section_3_3",
            },
        ],
        items2: [
            {
                key: 4,
                src: require("@/assets/home/section3-4.png"),
                text: "home.s3_title_4",
                subText: "home.desc_section_3_4",
            },
            {
                key: 5,
                src: require("@/assets/home/section3-5.png"),
                text: "home.s3_title_5",
                subText: "home.desc_section_3_5",
            },
        ],
    }),
}

export const walletNetworkSection4Mixin = {
    components: {
        EvoEllipse,
    },
    data: () => ({
        items: [
            {
                key: 1,
                src: require("@/assets/home/section2-2.png"),
                title: "evo_payment",
                subtitle: "home.subtitle_section_4_1",
                text: "home.text_section_4_1",
                path: "/cross-border-e-payment",
            },
            {
                key: 2,
                src: require("@/assets/home/section2-3.png"),
                title: "evo_transfer",
                subtitle: "home.subtitle_section_4_2",
                text: "home.text_section_4_2",
                path: "/inter-wallet-remittance",
            },
        ],
    }),
    methods: {
        goTo(link) {
            this.$router.push(link).catch(() => { });
        },
    },
}

export const walletNetworkSection6Mixin = {
    components: {
        EvoRect,
    },
    methods: {
        goTo(link) {
            this.$router.push(link).catch(() => { });
        },
    },
}

export const walletNetworkSection7Mixin = {
    components: {
        EvoCodeViewer,
    },
    methods: {
        onOpenAPIs() {
            window.location.href = "https://developer.evonetonline.com/";
        },
    },
    data: () => ({
        req: `curl --location --request POST 'https://testing.evonetonline.com/evonet/v0/payment/wop/cpmtoken' \
    --data-raw '{
        "brandID": "EVONET",
        "userData": {
            "wopUserReference": "Alex"
        }
    }'
      `,
        res: `{
        "result": {
            "code": "S0000",
            "message": "Success."
        },
        "mopID": "EVOWallet",
        "mopToken": [
            {
                "type": "QuickResponseCode",
                "value": "12489346795845340483074459525403",
                "expiryDate": "20220731180000+0800"
            }
        ]
    }
        `,
    }),
}

export const walletNetworkSection8Mixin = {
    data: () => ({
        items1: [
            {
                key: 1,
                src: require("@/assets/home/hong-kong-custom.png"),
                title: "home.title_section_8_1",
            },
            {
                key: 2,
                src: require("@/assets/home/mas.png"),
                title: "home.title_section_8_2",
            },
            {
                key: 3,
                src: require("@/assets/home/sfa.png"),
                title: "home.title_section_8_3",
            },
        ],
        items2: [
            {
                key: 3,
                src: require("@/assets/home/pricewaterhouse-coopers.png"),
                title: "home.title_section_8_4",
            },
            {
                key: 4,
                src: require("@/assets/home/pci-dss.png"),
                title: "home.title_section_8_5",
            },
            {
                key: 5,
                src: require("@/assets/home/iso-27001.png"),
                title: "home.title_section_8_6",
            },
        ],
        slideItems: [
            {
                key: 1,
                src: require("@/assets/home/temp.png"),
            },
            {
                key: 2,
                src: require("@/assets/home/temp.png"),
            },
            {
                key: 3,
                src: require("@/assets/home/temp.png"),
            },
            {
                key: 4,
                src: require("@/assets/home/temp.png"),
            },
            {
                key: 5,
                src: require("@/assets/home/temp.png"),
            },
            {
                key: 6,
                src: require("@/assets/home/temp.png"),
            },
            {
                key: 7,
                src: require("@/assets/home/temp.png"),
            },
            {
                key: 8,
                src: require("@/assets/home/temp.png"),
            },
            {
                key: 9,
                src: require("@/assets/home/temp.png"),
            },
            {
                key: 10,
                src: require("@/assets/home/temp.png"),
            },
        ],
    }),
    components: {
        EvoRect,
        EvoEllipse,
    },
}