<template>
  <div class="section-7 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <div class="evo-section7-title evo-text-58">
        {{ $t('home.easy_to_read') }}
      </div>
      <div class="evo-section7-title evo-text-58 evo-mb-8">
        {{ $t('home.easy_to_use') }}
      </div>
      <div class="evo-section7-subtitle evo-text-30 evo-opensans-regular">
        {{ $t('home.desc_section_7_1') }}
      </div>
      <v-btn x-large rounded class="text-none evo-text-29 evo-doc-btn" dark @click="onOpenAPIs">{{ $t('get_open_apis') }}</v-btn>

      <EvoCodeViewer :code="req" colorHeader="#1F1F1F" color="#273045" background="#e3e9f2" />
      <EvoCodeViewer :code="res" language="json" :title="'home.response'" />
    </div>
  </div>
</template>

<script>
import { walletNetworkSection7Mixin } from '@/mixins/wallet-network.js';

export default {
  name: 'EvoMWalletNetworkSectionSeven',
  mixins: [walletNetworkSection7Mixin]
};
</script>

<style lang="scss" scoped>
.section-7 {
  background: $header_bg_color;
  position: relative;

  .evo-section7-title {
    color: $title_color;
  }

  .evo-section7-subtitle {
    color: #a0a3a8;
    margin-bottom: 96px;
  }

  .evo-doc-btn {
    min-width: 314px;
    background: #1f252d;
    letter-spacing: normal;
    margin-bottom: 64px;
  }
}
</style>
