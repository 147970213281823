<template>
  <div class="section-3 evo-ph-206 evo-pv-60">
    <div class="evo-text-28 evo-title">
      {{ $t("home.s3_title") }}
    </div>
    <v-row justify="space-between" class="evo-ph-nagitive">
      <v-col
        class="evo-provide-col evo-provide-row1-col"
        cols="12"
        sm="12"
        md="4"
        v-for="item in items"
        :key="item.key"
      >
        <v-row class="px-0">
          <div class="evo-provide-card d-flex flex-column" data-aos="fade-up">
            <div>
              <img loading="lazy" :src="item.src" class="evo-section3-img" />
            </div>
            <div class="evo-provide-text evo-text-15">
              {{ $t(item.text) }}
            </div>
            <div class="evo-provide-subtext evo-text-12">
              {{ $t(item.subText) }}
            </div>
          </div>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="space-between">
      <v-col
        class="evo-provide-col"
        cols="12"
        sm="12"
        md="6"
        v-for="item in items2"
        :key="item.key"
      >
        <v-row class="px-0">
          <div class="evo-provide-card d-flex flex-column" data-aos="fade-up">
            <div>
              <img loading="lazy" :src="item.src" class="evo-section3-img" />
            </div>
            <div class="evo-provide-text evo-text-15">
              {{ $t(item.text) }}
            </div>
            <div class="evo-provide-subtext evo-text-12">
              {{ $t(item.subText) }}
            </div>
          </div>
        </v-row>
      </v-col>
    </v-row>

    <div class="evo-section3-bg"></div>
  </div>
</template>

<script>
import { walletNetworkSection3Mixin } from '@/mixins/wallet-network.js';
export default {
  name: "EvoHomeSectionThree",
  mixins: [walletNetworkSection3Mixin]
};
</script>

<style lang="scss" scoped>
.section-3 {
  background: #fafafa;
  position: relative;
  padding-top: 100px !important;
  padding-bottom: 100px !important;

  .evo-section3-bg {
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    background-image: url("~@/assets/home/bgcurve.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .evo-title {
    position: relative;
    z-index: 1;
    text-align: center;
    color: #1f1f1f;
    margin-bottom: 64px;
  }

  .evo-text-provide {
    font-weight: bold;
    color: #1f1f1f;
  }

  .evo-ph-nagitive {
    margin-left: -24%;
    margin-right: -24%;
  }

  .evo-provide-col {
    position: relative;
    z-index: 1;
    width: 48% !important;
    max-width: 48% !important;

    .evo-section3-img {
      width: 48px;
      height: 48px;
      margin-bottom: 3px;
    }

    .evo-position-item {
      z-index: 1;
      position: absolute;
      left: 0;
      top: -30px;
    }
  }

  .evo-provide-row1-col {
    width: 31.5% !important;
    max-width: 31.5% !important;
    margin-bottom: 48px;
  }

  .evo-provide-card {
    min-height: 200px;
    padding: 18px 15px;
    border-radius: 17px;
    box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.03);
    background: #ffffff;

    .evo-provide-text {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .evo-provide-subtext {
      font-family: "OpenSans-Regular" !important;
    }
  }
}
</style>
