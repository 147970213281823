var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-6 evo-ph-206 evo-pv-60",class:{
    'evo-pv-100': _vm.$vuetify.breakpoint.xsOnly,
    'evo-ph-80': _vm.$vuetify.breakpoint.xsOnly,
  }},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"12","md":"5"}},[_c('div',{staticClass:"evo-section6-img-container d-flex flex-column justify-center align-center",class:{
          'evo-img-auto': _vm.$vuetify.breakpoint.xsOnly,
          'evo-px-mb-28': _vm.$vuetify.breakpoint.xsOnly,
        }},[_c('img',{staticClass:"evo-section6-img",class:{ 'evo-img-auto': _vm.$vuetify.breakpoint.xsOnly },attrs:{"loading":"lazy","src":require("@/assets/home/phone-wallet-pay.png"),"data-aos":"fade-up"}})])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"12","md":"7","align-self":"center"}},[_c('div',{staticClass:"d-flex flex-column mb-16",staticStyle:{"height":"100%"}},[_c('div',[_c('div',{staticClass:"evo-section6-title evo-text-21",class:{
              'mb-8': _vm.$vuetify.breakpoint.xsOnly,
              'evo-px-text-21': _vm.$vuetify.breakpoint.xsOnly,
            },attrs:{"data-aos":"fade-up","data-aos-delay":"100"}},[_vm._v(" "+_vm._s(_vm.$t("home.a_powerful_wallet_demo_app"))+" ")]),_c('div',[_c('div',{staticClass:"evo-section6-subtitle evo-text-12 mb-2 evo-opensans-regular",class:{
                'evo-px-text-9': _vm.$vuetify.breakpoint.xsOnly,
              },attrs:{"data-aos":"fade-up","data-aos-delay":"150"}},[_vm._v(" "+_vm._s(_vm.$t( "home.evo_wallet_has_been_implemented_with_all_evonet_products" ))+" ")]),_c('div',{staticClass:"evo-section6-subtitle evo-text-12 evo-mb-28 evo-opensans-regular",class:{
                'mb-10': _vm.$vuetify.breakpoint.xsOnly,
                'evo-px-text-9': _vm.$vuetify.breakpoint.xsOnly,
              },attrs:{"data-aos":"fade-up","data-aos-delay":"200"}},[_vm._v(" "+_vm._s(_vm.$t( "home.contact_us_and_get_the_demo_to_start_the_user_journey" ))+" ")])]),_c('v-btn',{staticClass:"text-none evo-contact-btn",class:{ 'mb-10': _vm.$vuetify.breakpoint.xsOnly },attrs:{"x-large":"","rounded":"","dark":"","data-aos":"fade-up","data-aos-delay":"250"},on:{"click":function($event){return _vm.goTo('/contact-us')}}},[_vm._v(" "+_vm._s(_vm.$t("home.learn_more"))+" ")])],1)])])],1),_c('EvoRect',{staticClass:"evo-section6-rect1 mb-2"}),_c('EvoRect',{staticClass:"evo-section6-rect2",attrs:{"color":"skyBlue"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }