<template>
  <div class="section-2 evo-f-center evo-pv-100">
    <div class="evo-mobile">
      <div class="evo-text-58 evo-mb-8 text-center evo-title">
        {{ $t('home.s2_title') }}
      </div>
      <div class="evo-text-30 text-center evo-opensans-regular evo-sub-title">
        {{ $t('home.s2_sub_title') }}
      </div>

      <v-row class="evo-row" justify="space-between">
        <v-col cols="12" md="6" v-for="(item, index) in introduces" :key="index">
          <div class="evo-introduces">
            <div class="evo-circle-dot"></div>
            <div class="evo-opensans-regular evo-item-desc evo-text-36">
              {{ $t(item.desc) }}
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row class="evo-row">
        <v-col cols="12" md="3" v-for="(item, index) in items" :key="index">
          <div class="evo-items">
            <img loading="lazy" :src="item.src" class="evo-item-img" />
            <div class="evo-item-name evo-text-40 evo-opensans-regular">{{ $t(item.name) }}</div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { walletNetworkSection2Mixin } from '@/mixins/wallet-network.js';
export default {
  name: 'EvoMWalletNetworkSectionTwo',
  mixins: [walletNetworkSection2Mixin]
};
</script>

<style lang="scss" scoped>
.section-2 {
  position: relative;

  .evo-title {
    color: $title_color;
  }

  .evo-sub-title {
    color: #a0a3a8;
    margin-bottom: 64px;
  }

  .evo-introduces {
    display: flex;
    align-items: baseline;
    margin-bottom: 64px;

    .evo-item-desc {
      color: #1f1f1f;
    }
  }

  .evo-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;

    .evo-item-img {
      width: 346px;
      height: auto;
      margin-bottom: 18px;
    }

    .evo-item-name {
      color: #a0a3a8;
    }
  }

  .evo-circle-dot {
    &::before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      background: #2161ff;
      border-radius: 50%;
      margin-right: 20px;
    }
  }
}
</style>
