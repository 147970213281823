<template>
  <div
    class="section-6 evo-ph-206 evo-pv-60"
    :class="{
      'evo-pv-100': $vuetify.breakpoint.xsOnly,
      'evo-ph-80': $vuetify.breakpoint.xsOnly,
    }"
  >
    <v-row justify="space-between">
      <v-col class="pa-0" cols="12" sm="12" md="5">
        <div
          class="
            evo-section6-img-container
            d-flex
            flex-column
            justify-center
            align-center
          "
          :class="{
            'evo-img-auto': $vuetify.breakpoint.xsOnly,
            'evo-px-mb-28': $vuetify.breakpoint.xsOnly,
          }"
        >
          <img loading="lazy" 
            src="@/assets/home/phone-wallet-pay.png"
            class="evo-section6-img"
            :class="{ 'evo-img-auto': $vuetify.breakpoint.xsOnly }"
            data-aos="fade-up"
          />
        </div>
      </v-col>
      <v-col class="pa-0" cols="12" sm="12" md="7" align-self="center">
        <div class="d-flex flex-column mb-16" style="height: 100%">
          <div>
            <div
              class="evo-section6-title evo-text-21"
              :class="{
                'mb-8': $vuetify.breakpoint.xsOnly,
                'evo-px-text-21': $vuetify.breakpoint.xsOnly,
              }"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {{ $t("home.a_powerful_wallet_demo_app") }}
            </div>
            <div>
              <div
                class="
                  evo-section6-subtitle evo-text-12
                  mb-2
                  evo-opensans-regular
                "
                :class="{
                  'evo-px-text-9': $vuetify.breakpoint.xsOnly,
                }"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                {{
                  $t(
                    "home.evo_wallet_has_been_implemented_with_all_evonet_products"
                  )
                }}
              </div>
              <div
                class="
                  evo-section6-subtitle
                  evo-text-12
                  evo-mb-28
                  evo-opensans-regular
                "
                :class="{
                  'mb-10': $vuetify.breakpoint.xsOnly,
                  'evo-px-text-9': $vuetify.breakpoint.xsOnly,
                }"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {{
                  $t(
                    "home.contact_us_and_get_the_demo_to_start_the_user_journey"
                  )
                }}
              </div>
            </div>
            <v-btn
              x-large
              rounded
              dark
              class="text-none evo-contact-btn"
              :class="{ 'mb-10': $vuetify.breakpoint.xsOnly }"
              @click="goTo('/contact-us')"
              data-aos="fade-up"
              data-aos-delay="250"
            >
              {{ $t("home.learn_more") }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <EvoRect class="evo-section6-rect1 mb-2" />
    <EvoRect color="skyBlue" class="evo-section6-rect2" />
  </div>
</template>

<script>
import { walletNetworkSection6Mixin } from '@/mixins/wallet-network.js';
export default {
  name: "EvoHomeSectionSix",
  mixins: [walletNetworkSection6Mixin]
};
</script>

<style lang="scss" scoped>
.section-6 {
  background: $header_bg_color;
  position: relative;

  .evo-section6-img-container {
    width: 190px;
    padding: 5px 3px;
    background: #fafafa;
    box-shadow: 2px 4px 54px 0px rgba(166, 180, 195, 0.5),
      0px 1px 7px 0px rgba(222, 225, 234, 0.5);
    border-radius: 18px;
  }

  .evo-section6-img {
    width: 180px;
  }

  .evo-section6-logo {
    width: 41px;
    height: 41px;
  }

  .evo-px-section6-logo {
    width: 41px;
    height: 41px;
  }

  .evo-section6-title {
    font-weight: bold;
    color: $title_color;
    margin-bottom: 17px;
  }

  .evo-section6-subtitle {
    color: #a0a3a8;
  }

  .evo-section6-rect1 {
    width: 235px;
    height: 47px;
    position: absolute;
    right: 0;
    bottom: 110px;
  }

  .evo-section6-rect2 {
    width: 388px;
    height: 29px;
    position: absolute;
    right: 0;
    bottom: 80px;
  }

  .evo-section6-rotate {
    position: absolute;
    left: -60px;
    bottom: 245px;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    width: 420px;
    justify-content: space-between;
    flex-direction: row;

    & > .v-divider {
      width: 64px;
      max-width: 64px;
    }

    .evo-section6-rotate-text {
      font-weight: bold;
      color: $title_color;
    }
  }

  .evo-px-section6-rotate {
    left: -40px;
  }

  .evo-contact-btn {
    width: 183px;
    background: #1f252d;
    letter-spacing: normal;
  }
}
</style>
