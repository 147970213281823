<template>
  <div class="section-1 section-1-bg evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <v-row align="start" justify="center" class="evo-px-pa-12 px-0">
        <!-- top -->
        <v-col class="px-0" cols="12" padless>
          <div class="evo-section1-title evo-text-66 evo-mt-34">
            {{ $t('home.s1_title') }}
          </div>
          <div class="evo-section1-subtitle evo-text-33 evo-mb-28 evo-opensans-regular">
            {{ $t('home.deliver_exceptional_business_values_to_mobile_wallets_networks_merchant_acquirers_and_financial_service_providers') }}
          </div>
          <router-link to="contact-us" class="router-link">
            <v-btn rounded x-large color="default" class="text-none text-center evo-contact-us">
              <span class="evo-text-29">{{ $t('contact_us') }}</span>
            </v-btn>
          </router-link>
        </v-col>
        <!-- bottom -->
        <v-col class="px-0" cols="12" padless>
          <img loading="lazy" src="@/assets/home/section1.png" class="evo-section1-img" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EvoMWalletNetworkSectionOne'
};
</script>

<style lang="scss" scoped>
.section-1 {
  background-color: $nav_color;
  position: relative;

  .evo-px-diamond {
    margin-left: 10px;
    margin-bottom: 24px;
  }

  .evo-section1-title {
    text-align: center;
    margin-bottom: 48px;
    white-space: pre-line;
  }

  .evo-section1-subtitle {
    text-align: center;
    margin-bottom: 96px;
  }

  .router-link {
    display: flex !important;
    justify-content: center;
    .evo-contact-us {
      @include whiteblue_btn;
      min-width: 280px;
      margin: auto;
    }
  }

  .evo-section1-img {
    width: 100%;
    height: auto;
  }
}
</style>
